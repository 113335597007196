<nav class="navbar navbar-dark navbar-theme-primary px-4 col-12 d-md-none">
    <a class="navbar-brand me-lg-5" href="../../index.html">
        <img class="navbar-brand-dark" src="../../assets/img/brand/light.svg" alt="Volt logo" /> <img
            class="navbar-brand-light" src="../../assets/img/brand/dark.svg" alt="Volt logo" />
    </a>
    <div class="d-flex align-items-center">
        <button class="navbar-toggler d-md-none collapsed" type="button" data-bs-toggle="collapse"
            data-bs-target="#sidebarMenu" aria-controls="sidebarMenu" aria-expanded="false"
            aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
    </div>
</nav>
<nav id="sidebarMenu" style="background-color: #ffffff !important;border-right: 1px solid #f3f3f3 !important;"
    class="sidebar d-md-block bg-dark text-white collapse" data-simplebar>
    <div class="pt-3">
        <div
            class="user-card d-flex d-md-none align-items-center justify-content-between justify-content-md-center pb-4">
            <div class="d-flex align-items-center">
                <div class="user-avatar lg-avatar me-4">
                    <img src="../../assets/img/team/profile-picture-3.jpg"
                        class="card-img-top rounded-circle border-white" alt="Bonnie Green">
                </div>
                <div class="d-block">
                    <h2 class="h6">Hi,</h2>
                    <a href="javascript: void()" class="btn btn-secondary text-dark btn-xs"><span class="me-2"><span
                                class="fas fa-sign-out-alt"></span></span>Sign Out</a>
                </div>
            </div>
            <div class="collapse-close d-md-none">
                <a href="#sidebarMenu" class="fas fa-times" data-bs-toggle="collapse" data-bs-target="#sidebarMenu"
                    aria-controls="sidebarMenu" aria-expanded="true" aria-label="Toggle navigation">
                </a>
            </div>
        </div>
        <ul class="nav flex-column pt-3 pt-md-0">
            <li class="nav-item" style="margin-bottom: 15px;">
                <a href="javascript: void()" class="nav-link d-flex align-items-center">
                    <span class="sidebar-icon">
                        <img src="../../assets/img/brand/light.svg" height="20" width="20" alt="Volt Logo">
                    </span>
                    <span class="mt-1 sidebar-text">HOMELIFE APP</span>
                </a>
            </li>

            <li class="nav-item" [ngClass]="{'active': active_nav == 'template'}" (click)="active_nav = 'template'">
                <a [routerLink]="[ '/dashboard' ]" routerLinkActive="active" class="nav-link">
                    <span class="sidebar-icon material-icons-outlined">
                        design_services
                    </span>
                    <span class="sidebar-text">Templates</span>
                </a>
            </li>
            <li class="nav-item" [ngClass]="{'active': active_nav == 'accreq'}" (click)="active_nav = 'accreq'">
                <a [routerLink]="[ '/dashboard/accessreq' ]" routerLinkActive="active" class="nav-link">
                    <span class="sidebar-icon material-icons-outlined">
                        verified_user
                    </span>
                    <span class="sidebar-text">Access Request</span>
                </a>
            </li>
            <li class="nav-item" [ngClass]="{'active': active_nav == 'users'}" (click)="active_nav = 'users'">
                <a [routerLink]="[ '/dashboard/users' ]" routerLinkActive="active" class="nav-link">
                    <span class="sidebar-icon material-icons-outlined">
                        people
                    </span>
                    <span class="sidebar-text">Users Management</span>
                </a>
            </li>
            <li class="nav-item" [ngClass]="{'active': active_nav == 'config'}" (click)="active_nav = 'config'">
                <a [routerLink]="[ '/dashboard/config' ]" routerLinkActive="active" class="nav-link">
                    <span class="sidebar-icon material-icons-outlined">
                        toggle_on
                    </span>
                    <span class="sidebar-text">Configuration</span>
                </a>
            </li>

            <li class="nav-item" [ngClass]="{'active': active_nav == 'support'}" (click)="active_nav = 'support'">
                <a [routerLink]="[ '/dashboard/support' ]" routerLinkActive="active" class="nav-link">
                    <span class="sidebar-icon material-icons-outlined">
                        support_agent
                    </span>
                    <span class="sidebar-text">Support</span>
                </a>
            </li>
        </ul>
    </div>
    <div style="color: black;
    width: 100%;
    height: 120px;
    padding: 10px;
    font-family: Segoe UI,Helvetica,Arial,sans-serif;
    font-size: 14px;
    font-weight: 400;
    background-color: #f5f5f56b;
    bottom: 0px;
    position: absolute;">
        <span style="font-weight: 500;">Info</span>
        <br>
        <span> For more information and queries please contact {{"support@aramic.com"}} </span>
    </div>
</nav>

<main class="content" style="padding:0px !important;">

    <nav class="navbar navbar-top navbar-expand navbar-dashboard navbar-dark ps-0 pe-2 pb-0" style="background-color: rgb(255, 255, 255);
    border-radius: 0px !important;
    border-bottom: 1px solid #f3f3f3 !important;
    /* box-shadow: 1px 1px 0px 0px rgb(0 0 0 / 0%);
    -webkit-box-shadow: 3px 1px 3px -1px rgb(0 0 0 / 40%);
    -moz-box-shadow: 3px 1px 10px -2px rgba(0,0,0,0.61); */
    padding-bottom: 5px !important;
    padding-left: 10px !important;">
        <div class="container-fluid px-0">
            <div class="d-flex justify-content-between w-100" id="navbarSupportedContent">
                <div class="d-flex align-items-center">
                    <button id="sidebar-toggle"
                        class="sidebar-toggle me-3 btn btn-icon-only btn-lg btn-circle d-none d-md-inline-block"><span
                            class="fas fa-bars"></span>
                    </button>
                </div>
            </div>
        </div>
    </nav>

    <router-outlet></router-outlet>

</main>